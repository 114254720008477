import { Link, useParams } from "react-router-dom";
import { Detail, Printer } from '@styled-icons/boxicons-solid'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import APP_CONFIG from "../../utils/APP_CONFIG";
import StringUtil from "../../utils/StringUtil";
import TokenUtil from "../../utils/TokenUtil";
import { ComponentToPrint } from "../../components/ComponentToPrint";
import { useReactToPrint } from "react-to-print";

export default function CommonProductHistoryPage() {

    const componentRef = useRef();
    const [product, setProduct] = useState({})
    const [productLabels, setProductLabels] = useState([])
    const { product_uuid } = useParams();
    const [labels, setLabels] = useState([])

    useEffect(() => {
        loadProduct()
        loadProductLabels()
    }, [])

    const printLabelItem = (uuid) => {
        axios.get(APP_CONFIG.API_URL + `products/${uuid}/label-item-histories?p=1&s=50`, {
            headers: {
                Authorization: 'Bearer ' + TokenUtil.getApiToken()
            }
        }).then((res) => {
            setLabels(res.data.data.label_item.data)
        })
    }

    const loadProduct = () => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `products/${product_uuid}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setProduct(res.data.data)
        })
    }

    const loadProductLabels = () => {
        axios.get(APP_CONFIG.API_URL + `products/${product_uuid}/label-histories`, {
            headers: {
                Authorization: 'Bearer ' + TokenUtil.getApiToken()
            }
        }).then((res) => {
            setProductLabels(res.data.data.label_dates.data)
        })
    }

    const handlePrint = useReactToPrint({
        pageStyle: `@page { size: 3.5in 1in; margin: 0; }`,
        content: () => componentRef.current,
    });

    return (
        <div>
            <div style={{ display: 'none' }}>
                {
                    labels.length > 0 ?
                        <ComponentToPrint ref={componentRef} labels={labels} handlePrint={handlePrint} /> : <></>
                }
            </div>

            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Produk</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/panel/common-product'}>List Produk</Link></li>
                                <li className="breadcrumb-item">Riwayat Generate Label</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className='mt-3 mt-lg-5 mb-3 text-primary font-weight-bold'>Riwayat Generate label Produk</h3>

            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className='p-4 border'>
                                <table className='table' style={{ tableLayout: 'auto' }}>
                                    <thead>
                                        <tr>
                                            <td><b>Nama Produk</b></td>
                                            <td><b>:</b></td>
                                            <td>{product.product_name}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Kode Produk</b></td>
                                            <td><b>:</b></td>
                                            <td>{product.product_code}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Harga</b></td>
                                            <td><b>:</b></td>
                                            <td>Rp{StringUtil.formatRupiah(product.price)}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Gambar</b></td>
                                            <td><b>:</b></td>
                                            <td>
                                                <img src={product.photo} width={80} className='img-thumbnail' alt="" />
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Waktu</th>
                                        <th>Jumlah Generate</th>
                                        <th className='text-center'>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productLabels.map((productLabel, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{productLabel.create_timestamp}</td>
                                                    <td>{StringUtil.formatRupiah(productLabel.amount_label_item)}</td>
                                                    <td>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <Link to={`/panel/common-product/${product_uuid}/history/${productLabel.uuid}`} className="btn btn-sm btn-success mr-2"><Detail className="mr-1" /> Lihat</Link>
                                                            <a href="#" onClick={() => {
                                                                printLabelItem(productLabel.uuid)
                                                            }} className="btn btn-sm btn-primary"><Printer className="mr-1" /> Cetak</a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}