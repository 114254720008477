import { useEffect, useRef, useState } from 'react';
import { QRNormal } from 'react-qrbtf'
import APP_CONFIG from '../utils/APP_CONFIG';
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
    width: 175,
    height: 175,
    type: "svg",
    dotsOptions: {
        color: "#666666",
        type: "dots"
    },
    backgroundOptions: {
        color: "#FFFFFF",
    },
    cornersSquareOptions: {
        type: 'dot',
        color: '#333333'
    },
    cornersDotOptions: {
        type: 'dot',
        color: '#333333'
    }
});

export const QrCodeComponent = (props) => {
    const [serialNumber, setSerialNumber] = useState('')
    const ref = useRef(null);

    useEffect(() => {
        setSerialNumber(APP_CONFIG.WEB_URL + 'verify/' + props.value)
        qrCode.append(ref.current);
    }, [props.value])

    return (
        <div style={{ position: 'relative', border: '3px solid #000', borderRadius: 10 }}>
            <QRNormal type={'round'} funcType={'B'} posType={'round'} value={serialNumber} size={50} />
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 30, padding: 2, borderRadius: 8, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={require('./../assets/images/logo/garuda-black.png')} width={'100%'} height={'100%'} style={{ borderRadius: 8, }} />
            </div>
        </div>
    )
}
