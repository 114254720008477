import axios from "axios"
import { useState } from "react"
import APP_CONFIG from "../../utils/APP_CONFIG"

export default function CustomTemaPage() {

    const [productCode, setProductCode] = useState()

    const doVerif = () => {
        axios.get(APP_CONFIG.API_URL + 'products/product-code/' + productCode)
            .then((res) => {
                alert('Berhasil, Serial Number Valid')
            }).catch((err) => {
                alert('Gagal, Serial Number Tidak Valid')
            })
    }

    return (
        <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '100vh' }}
        >
            <h1>Halaman Custom Tema</h1>
        </div>
    )
}