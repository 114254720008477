import { NavLink } from "react-router-dom"
import { ChevronRight, ChevronDown, Save, CloudUpload, Trash } from 'styled-icons/boxicons-solid'
import { GridVertical } from 'styled-icons/boxicons-regular'
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from 'react-router';
import APP_CONFIG from "../../utils/APP_CONFIG";
import axios from "axios";
import noImage from './../../assets/images/samples/noimage.png'
import { ToastContainer, toast } from 'react-toastify';
import { Scrollbar } from 'react-scrollbars-custom';

const finalSections = [
    {
        id: 'hero',
        name: 'Hero',
    },
    {
        id: 'section_rtl',
        name: 'Section RTL',
    },
    {
        id: 'section_ltr',
        name: 'Section LTR',
    },
    {
        id: 'gallery',
        name: 'Galeri',
    },
    {
        id: 'footer',
        name: 'Footer',
    }
]

const CustomSpecialProductPage = () => {
    const [sections, updateSections] = useState(finalSections);
    const [color, setColor] = useState('#000000')
    const editorRef = useRef(null);
    const [activedChevron, setActivedChevron] = useState(null)
    const params = useParams()
    const [themeObj, setThemeObj] = useState({})
    const [galleries, setGalleries] = useState([])
    const [heroLogoObj, setHeroLogoObj] = useState(null)
    const [heroImageObj, setHeroImageObj] = useState(null)
    const [sectionRtlImageObj, setSectionRtlImageObj] = useState(null)
    const [sectionLtrImageObj, setSectionLtrImageObj] = useState(null)
    const [changesKeyIframe, setChangesKeyIframe] = useState(0)
    const heroContentRef = useRef(null)
    const sectionRtlContentRef = useRef(null)
    const sectionLtrContentRef = useRef(null)
    const [tempGalleries, setTempGalleries] = useState([])
    const [imageGalleryUpload, setImageGalleryUpload] = useState(null)

    useEffect(() => {
        loadTheme()
    }, [])

    const loadTheme = () => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `special-product-websites/${params.product_uuid}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setThemeObj(res.data.data)
            loadGalleries(res.data.data.uuid)
        })
    }

    const doUploadGallery = () => {
        const token = localStorage.getItem('api_token')
        const formData = new FormData();

        formData.append('image', imageGalleryUpload)

        axios.post(APP_CONFIG.API_URL + `special-product-website-has-galleries/${themeObj.uuid}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            loadTheme()
            setImageGalleryUpload(null)
            document.getElementById('gallery_upload').value = ''

            setChangesKeyIframe(changesKeyIframe + 1)
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const doUpdateTheme = () => {
        const token = localStorage.getItem('api_token')
        const formData = new FormData();

        formData.append('order', JSON.stringify(sections.map((e) => (e.id))))
        formData.append('hero_is_active', themeObj.hero_is_active)
        formData.append('hero_background_color', themeObj.hero_background_color)
        formData.append('hero_logo_is_active', themeObj.hero_logo_is_active)
        formData.append('hero_image_is_active', themeObj.hero_image_is_active)
        formData.append('hero_title_is_active', themeObj.hero_title_is_active)
        formData.append('hero_title', themeObj.hero_title)
        formData.append('hero_title_color', themeObj.hero_title_color)
        formData.append('hero_content_is_active', themeObj.hero_content_is_active)
        formData.append('hero_content', heroContentRef.current.getContent())
        formData.append('hero_button_is_active', themeObj.hero_button_is_active)
        formData.append('hero_button_text', themeObj.hero_button_text)
        formData.append('hero_button_link', themeObj.hero_button_link)
        formData.append('hero_button_background_color', themeObj.hero_button_background_color)
        formData.append('section_rtl_is_active', themeObj.section_rtl_is_active)
        formData.append('section_rtl_background_color', themeObj.section_rtl_background_color)
        formData.append('section_rtl_background_type', themeObj.section_rtl_background_type)
        formData.append('section_rtl_image_is_active', themeObj.section_rtl_image_is_active)
        formData.append('section_rtl_title_is_active', themeObj.section_rtl_title_is_active)
        formData.append('section_rtl_title', themeObj.section_rtl_title)
        formData.append('section_rtl_title_color', themeObj.section_rtl_title_color)
        formData.append('section_rtl_content_is_active', themeObj.section_rtl_content_is_active)
        formData.append('section_rtl_content', sectionRtlContentRef.current.getContent())
        formData.append('section_rtl_button_is_active', themeObj.section_rtl_button_is_active)
        formData.append('section_rtl_button_text', themeObj.section_rtl_button_text)
        formData.append('section_rtl_button_link', themeObj.section_rtl_button_link)
        formData.append('section_rtl_button_background_color', themeObj.section_rtl_button_background_color)
        formData.append('section_ltr_is_active', themeObj.section_ltr_is_active)
        formData.append('section_ltr_background_color', themeObj.section_ltr_background_color)
        formData.append('section_ltr_background_type', themeObj.section_ltr_background_type)
        formData.append('section_ltr_image_is_active', themeObj.section_ltr_image_is_active)
        formData.append('section_ltr_title_is_active', themeObj.section_ltr_title_is_active)
        formData.append('section_ltr_title', themeObj.section_ltr_title)
        formData.append('section_ltr_title_color', themeObj.section_ltr_title_color)
        formData.append('section_ltr_content_is_active', themeObj.section_ltr_content_is_active)
        formData.append('section_ltr_content', sectionLtrContentRef.current.getContent())
        formData.append('section_ltr_button_is_active', themeObj.section_ltr_button_is_active)
        formData.append('section_ltr_button_text', themeObj.section_ltr_button_text)
        formData.append('section_ltr_button_link', themeObj.section_ltr_button_link)
        formData.append('section_ltr_button_background_color', themeObj.section_ltr_button_background_color)
        formData.append('gallery_is_active', themeObj.gallery_is_active)
        formData.append('gallery_background_color', themeObj.gallery_background_color)
        formData.append('gallery_background_type', themeObj.gallery_background_type)
        formData.append('gallery_title_is_active', themeObj.gallery_title_is_active)
        formData.append('gallery_title', themeObj.gallery_title)
        formData.append('gallery_title_color', themeObj.gallery_title_color)
        formData.append('gallery_button_is_active', themeObj.gallery_button_is_active)
        formData.append('gallery_button_text', themeObj.gallery_button_text)
        formData.append('gallery_button_link', themeObj.gallery_button_link)
        formData.append('gallery_button_background_color', themeObj.gallery_button_background_color)
        formData.append('footer_is_active', themeObj.footer_is_active)
        formData.append('footer_text', themeObj.footer_text)
        formData.append('footer_background_color', themeObj.footer_background_color)

        if (heroLogoObj) {
            formData.append('hero_logo', heroLogoObj)
        }

        if (heroImageObj) {
            formData.append('hero_image', heroImageObj)
        }

        if (sectionRtlImageObj) {
            formData.append('section_rtl_image', sectionRtlImageObj)
        }

        if (sectionLtrImageObj) {
            formData.append('section_ltr_image', sectionLtrImageObj)
        }

        axios.post(APP_CONFIG.API_URL + `special-product-websites/${params.product_uuid}/update`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer " + token
            },
        }).then((res) => {
            loadTheme()

            setHeroLogoObj(null)
            document.getElementById('hero_logo').value = ''

            setHeroImageObj(null)
            document.getElementById('hero_image').value = ''

            setSectionLtrImageObj(null)
            document.getElementById('section_ltr_image').value = ''

            setSectionRtlImageObj(null)
            document.getElementById('section_rtl_image').value = ''

            setChangesKeyIframe(changesKeyIframe + 1)
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const loadGalleries = (theme_uuid) => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `special-product-website-has-galleries/${theme_uuid}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setGalleries(res.data.data.special_product_website_has_galleries)
        })
    }

    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateSections(items);

        setThemeObj(() => {
            let obj = Object.assign({}, themeObj)
            obj.order = JSON.stringify(items.map((e) => (e.id)))

            return obj
        })
    }

    const setStateActivedChevron = (id, event) => {
        if (activedChevron == id) {
            setActivedChevron(null)
        } else {
            setActivedChevron(id)
        }
    }

    const doDeleteGallery = (imageUuid) => {
        const token = localStorage.getItem('api_token')

        axios.delete(APP_CONFIG.API_URL + `special-product-website-has-galleries/${imageUuid}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            loadTheme()

            setChangesKeyIframe(changesKeyIframe + 1)
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const _renderCategoryItemForm = (id) => {
        if (id == 'hero') {
            return (
                <div>
                    <div class="d-flex col-12 mx-auto my-1 px-0">
                        <p class="my-auto mr-auto color90">
                            Matikan/Hidupkan</p>
                        <Switch checked={themeObj.hero_is_active == 1} onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.hero_is_active = e ? 1 : 0

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="hero_background_color" class="col-form-label">Warna Background</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.hero_background_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.hero_background_color} id="hero_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.hero_background_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="hero_logo" class="col-form-label d-flex align-items-center">
                            Logo
                            <Switch checked={themeObj.hero_logo_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.hero_logo_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <img src={heroLogoObj ? URL.createObjectURL(heroLogoObj) : (themeObj.hero_logo ? themeObj.hero_logo : noImage)} width={100} className="mb-2 rounded" alt="" />
                        <input accept="image/*" type="file" name="hero_logo" id="hero_logo" className="form-control" onChange={(e) => {
                            setHeroLogoObj(e.target.files[0])
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="hero_image" class="col-form-label d-flex align-items-center">
                            Gambar
                            <Switch checked={themeObj.hero_image_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.hero_image_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <img src={heroImageObj ? URL.createObjectURL(heroImageObj) : (themeObj.hero_image ? themeObj.hero_image : noImage)} width={100} className="mb-2 rounded" alt="" />
                        <input accept="image/*" type="file" name="hero_image" id="hero_image" className="form-control" onChange={(e) => {
                            setHeroImageObj(e.target.files[0])
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="hero_title" className="col-form-label d-flex align-items-center">
                            Judul
                            <Switch checked={themeObj.hero_title_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.hero_title_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <input type="text" name="" className="form-control" id="hero_title" value={themeObj.hero_title} onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.hero_title = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="hero_title_color" class="col-form-label">Warna Text Judul</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.hero_title_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.hero_title_color} id="hero_title_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.hero_title_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="hero_content_is_active" className="col-form-label d-flex align-items-center">
                            Konten
                            <Switch checked={themeObj.hero_content_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.hero_content_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <Editor
                            onInit={(evt, editor) => heroContentRef.current = editor}
                            initialValue={themeObj.hero_content}
                            init={{
                                height: 200,
                                menubar: false,
                                toolbar: 'fontsize | bold italic forecolor | alignleft aligncenter alignright alignjustify',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px'
                            }}
                        />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="hero_button_is_active" className="col-form-label d-flex align-items-center">
                            Tombol
                            <Switch checked={themeObj.hero_button_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.hero_button_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <label for="hero_button_background_color" class="mt-n4 col-form-label">Background Tombol</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: 'unset', padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.hero_button_background_color} id="hero_button_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.hero_button_background_color = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="hero_button_text" className="col-form-label d-flex align-items-center">
                            Text Tombol
                        </label>
                        <input type="text" name="hero_button_text" value={themeObj.hero_button_text} className="form-control" id="hero_button_text" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.hero_button_text = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="hero_button_link" className="col-form-label d-flex align-items-center">
                            Link Tombol
                        </label>
                        <input type="text" name="hero_button_link" value={themeObj.hero_button_link} className="form-control" id="hero_button_link" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.hero_button_link = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                </div>
            )
        } else if (id == 'section_rtl') {
            return (
                <div>
                    <div class="d-flex col-12 mx-auto my-1 px-0">
                        <p class="my-auto mr-auto color90">
                            Matikan/Hidupkan</p>
                        <Switch checked={themeObj.section_rtl_is_active == 1} className="ml-2" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_is_active = e ? 1 : 0

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_rtl_background_color" class="col-form-label">Warna Background</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.section_rtl_background_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.section_rtl_background_color} id="section_rtl_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_background_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_rtl_background_type" class="col-form-label">Tipe Background</label>
                        <select name="section_rtl_background_type" className="form-control" id="section_rtl_background_type" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_background_type = e.target.value

                                return obj
                            })
                        }}>
                            <option value="solid" selected={themeObj.section_rtl_background_type == 'solid'}>Solid</option>
                            <option value="detailed" selected={themeObj.section_rtl_background_type == 'detailed'}>Detail</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_rtl_image" class="col-form-label d-flex align-items-center">
                            Gambar
                            <Switch checked={themeObj.section_rtl_image_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_rtl_image_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <img src={sectionRtlImageObj ? URL.createObjectURL(sectionRtlImageObj) : (themeObj.section_rtl_image ? themeObj.section_rtl_image : noImage)} width={100} className="mb-2 rounded" alt="" />
                        <input accept="image/*" type="file" name="section_rtl_image" id="section_rtl_image" className="form-control" onChange={(e) => {
                            setSectionRtlImageObj(e.target.files[0])
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="section_rtl_title" className="col-form-label d-flex align-items-center">
                            Judul
                            <Switch checked={themeObj.section_rtl_title_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_rtl_title_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <input type="text" name="" className="form-control" id="section_rtl_title" value={themeObj.section_rtl_title} onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_title = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_rtl_title_color" class="col-form-label">Warna Text Judul</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.section_rtl_title_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.section_rtl_title_color} id="section_rtl_title_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_title_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="section_rtl_content_is_active" className="col-form-label d-flex align-items-center">
                            Konten
                            <Switch checked={themeObj.section_rtl_content_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_rtl_content_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <Editor
                            onInit={(evt, editor) => sectionRtlContentRef.current = editor}
                            initialValue={themeObj.section_rtl_content}
                            init={{
                                height: 200,
                                menubar: false,
                                toolbar: 'fontsize | bold italic forecolor | alignleft aligncenter alignright alignjustify',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px'
                            }}
                        />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="section_rtl_button_is_active" className="col-form-label d-flex align-items-center">
                            Tombol
                            <Switch checked={themeObj.section_rtl_button_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_rtl_button_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <label for="section_rtl_button_background_color" class="mt-n4 col-form-label">Background Tombol</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: 'unset', padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.section_rtl_button_background_color} id="section_rtl_button_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_button_background_color = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="section_rtl_button_text" className="col-form-label d-flex align-items-center">
                            Text Tombol
                        </label>
                        <input type="text" name="section_rtl_button_text" value={themeObj.section_rtl_button_text} className="form-control" id="section_rtl_button_text" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_button_text = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="section_rtl_button_link" className="col-form-label d-flex align-items-center">
                            Link Tombol
                        </label>
                        <input type="text" name="section_rtl_button_link" value={themeObj.section_rtl_button_link} className="form-control" id="section_rtl_button_link" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_rtl_button_link = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                </div>
            )
        } else if (id == 'section_ltr') {
            return (
                <div>
                    <div class="d-flex col-12 mx-auto my-1 px-0">
                        <p class="my-auto mr-auto color90">
                            Matikan/Hidupkan</p>
                        <Switch checked={themeObj.section_ltr_is_active == 1} className="ml-2" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_is_active = e ? 1 : 0

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_ltr_background_color" class="col-form-label">Warna Background</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.section_ltr_background_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.section_ltr_background_color} id="section_ltr_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_background_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_ltr_background_type" class="col-form-label">Tipe Background</label>
                        <select name="section_ltr_background_type" className="form-control" id="section_ltr_background_type" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_background_type = e.target.value

                                return obj
                            })
                        }}>
                            <option value="solid" selected={themeObj.section_ltr_background_type == 'solid'}>Solid</option>
                            <option value="detailed" selected={themeObj.section_ltr_background_type == 'detailed'}>Detail</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_ltr_image" class="col-form-label d-flex align-items-center">
                            Gambar
                            <Switch checked={themeObj.section_ltr_image_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_ltr_image_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <img src={sectionLtrImageObj ? URL.createObjectURL(sectionLtrImageObj) : (themeObj.section_ltr_image ? themeObj.section_ltr_image : noImage)} width={100} className="mb-2 rounded" alt="" />
                        <input accept="image/*" type="file" name="section_ltr_image" id="section_ltr_image" className="form-control" onChange={(e) => {
                            setSectionLtrImageObj(e.target.files[0])
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="section_ltr_title" className="col-form-label d-flex align-items-center">
                            Judul
                            <Switch checked={themeObj.section_ltr_title_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_ltr_title_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <input type="text" name="" className="form-control" id="section_ltr_title" value={themeObj.section_ltr_title} onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_title = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="section_ltr_title_color" class="col-form-label">Warna Text Judul</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.section_ltr_title_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.section_ltr_title_color} id="section_ltr_title_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_title_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="section_ltr_content_is_active" className="col-form-label d-flex align-items-center">
                            Konten
                            <Switch checked={themeObj.section_ltr_content_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_ltr_content_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <Editor
                            onInit={(evt, editor) => sectionLtrContentRef.current = editor}
                            initialValue={themeObj.section_ltr_content}
                            init={{
                                height: 200,
                                menubar: false,
                                toolbar: 'fontsize | bold italic forecolor | alignleft aligncenter alignright alignjustify',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px'
                            }}
                            onEditorChange={(a, editor) => {
                                console.log(editor.getContent())
                            }}
                        />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="section_ltr_button_is_active" className="col-form-label d-flex align-items-center">
                            Tombol
                            <Switch checked={themeObj.section_ltr_button_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.section_ltr_button_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <label for="section_ltr_button_background_color" class="mt-n4 col-form-label">Background Tombol</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: 'unset', padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.section_ltr_button_background_color} id="section_ltr_button_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_button_background_color = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="section_ltr_button_text" className="col-form-label d-flex align-items-center">
                            Text Tombol
                        </label>
                        <input type="text" name="section_ltr_button_text" value={themeObj.section_ltr_button_text} className="form-control" id="section_ltr_button_text" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_button_text = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="section_ltr_button_link" className="col-form-label d-flex align-items-center">
                            Link Tombol
                        </label>
                        <input type="text" name="section_ltr_button_link" value={themeObj.section_ltr_button_link} className="form-control" id="section_ltr_button_link" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.section_ltr_button_link = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                </div>
            )
        } else if (id == 'gallery') {
            return (
                <div>
                    <div class="d-flex col-12 mx-auto my-1 px-0">
                        <p class="my-auto mr-auto color90">
                            Matikan/Hidupkan</p>
                        <Switch checked={themeObj.gallery_is_active == 1} className="ml-2" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.gallery_is_active = e ? 1 : 0

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="gallery_background_color" class="col-form-label">Warna Background</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.gallery_background_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.gallery_background_color} id="gallery_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.gallery_background_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="gallery_background_type" class="col-form-label">Tipe Background</label>
                        <select name="gallery_background_type" className="form-control" id="gallery_background_type" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.gallery_background_type = e.target.value

                                return obj
                            })
                        }}>
                            <option value="solid" selected={themeObj.gallery_background_type == 'solid'}>Solid</option>
                            <option value="detailed" selected={themeObj.gallery_background_type == 'detailed'}>Detail</option>
                        </select>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="gallery_title" className="col-form-label d-flex align-items-center">
                            Judul
                            <Switch checked={themeObj.gallery_title_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.gallery_title_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <input type="text" name="" className="form-control" id="gallery_title" value={themeObj.gallery_title} onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.gallery_title = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="gallery_button_is_active" className="col-form-label d-flex align-items-center">
                            Tombol
                            <Switch checked={themeObj.gallery_button_is_active == 1} className="ml-2" onChange={(e) => {
                                setThemeObj(() => {
                                    let obj = Object.assign({}, themeObj)
                                    obj.gallery_button_is_active = e ? 1 : 0

                                    return obj
                                })
                            }} />
                        </label>
                        <label for="gallery_button_background_color" class="mt-n4 col-form-label">Background Tombol</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: 'unset', padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.gallery_button_background_color} id="gallery_button_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.gallery_button_background_color = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="gallery_button_text" className="col-form-label d-flex align-items-center">
                            Text Tombol
                        </label>
                        <input type="text" name="gallery_button_text" value={themeObj.gallery_button_text} className="form-control" id="gallery_button_text" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.gallery_button_text = e.target.value

                                return obj
                            })
                        }} />
                        <label htmlFor="gallery_button_link" className="col-form-label d-flex align-items-center">
                            Link Tombol
                        </label>
                        <input type="text" name="gallery_button_link" value={themeObj.gallery_button_link} className="form-control" id="gallery_button_link" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.gallery_button_link = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="hero_title" className="col-form-label d-flex align-items-center">
                            Galeri
                        </label>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 15 }}>
                            {
                                galleries.length > 0 ? galleries.map((e) => {
                                    return (
                                        <div style={{ position: 'relative' }}>
                                            <img src={e.image} alt="" style={{ width: 75, height: 75, objectFit: 'cover', objectPosition: 'center' }} />

                                            <div style={{ position: 'absolute', width: 30, height: 30, borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', top: -10, right: -10 }} className="bg-danger text-white" onClick={() => {
                                                doDeleteGallery(e.uuid)
                                            }}>
                                                <Trash size={17} />
                                            </div>
                                        </div>
                                    )
                                }) : <p>Tidak ada galeri</p>
                            }
                        </div>
                        <div class="form-group mt-3">
                            <div class="input-group">
                                <input type="file" accept="image/*" class="form-control" id="gallery_upload" onChange={(e) => {
                                    setImageGalleryUpload(e.target.files[0])
                                }} />
                                <div class="input-group-append" style={{ cursor: 'pointer' }} onClick={() => {
                                    if (imageGalleryUpload) {
                                        doUploadGallery()
                                    }
                                }}><span class="input-group-text bg-primary text-white" ><CloudUpload size={17} /> Upload</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (id == 'footer') {
            return (
                <div>
                    <div class="d-flex col-12 mx-auto my-1 px-0">
                        <p class="my-auto mr-auto color90">
                            Matikan/Hidupkan</p>
                        <Switch checked={themeObj.footer_is_active == 1} className="ml-2" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.footer_is_active = e ? 1 : 0

                                return obj
                            })
                        }} />
                    </div>
                    <div class="form-group mb-2">
                        <label for="footer_background_color" class="col-form-label">Warna Background</label>
                        <img style={{ minWidth: '16px', minHeight: '16px', boxSizing: 'unset', boxShadow: 'none', background: themeObj.footer_background_color, padding: '0 6px', cursor: 'pointer' }} src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png" title="Select with ColorPick Eyedropper - See advanced option: &quot;Add ColorPick Eyedropper near input[type=color] fields on websites&quot;" class="colorpick-eyedropper-input-trigger" />
                        <input class="form-control" type="color" value={themeObj.footer_background_color} id="footer_background_color" colorpick-eyedropper-active="true" onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.footer_background_color = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="footer_text" className="col-form-label d-flex align-items-center">
                            Text
                        </label>
                        <input type="text" name="footer_text" className="form-control" id="footer_text" value={themeObj.footer_text} onChange={(e) => {
                            setThemeObj(() => {
                                let obj = Object.assign({}, themeObj)
                                obj.footer_text = e.target.value

                                return obj
                            })
                        }} />
                    </div>
                </div>
            )
        }
    }

    return (
        <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
            <ToastContainer />

            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Produk Spesial</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <NavLink to={'/panel/special-product'} className={'text-primary'}>List Produk Spesial</NavLink>
                                </li>
                                <li className="breadcrumb-item">Kustomisasi Tema</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className='mt-3 mt-lg-5 mb-4 text-primary font-weight-bold'>Kustomisasi Tema Produk Spesial</h3>

            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-3 col-12">
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-sm mt-2 mb-1 btn-primary d-flex align-items-center justify-content-center" onClick={doUpdateTheme} style={{ gap: 3 }}><Save size={17} /> Simpan</button>
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="sections">
                            {(provided) => (
                                <Scrollbar color="#DCA524">
                                    <div className="py-2 accordion" id="accordionItemUpdateTheme" {...provided.droppableProps} ref={provided.innerRef}>
                                        {sections.map(({ id, name }, index) => {
                                            return (
                                                <Draggable key={id} draggableId={id} index={index}>
                                                    {(provided) => (
                                                        <div className="card mb-1" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onClick={(e) => {
                                                            setStateActivedChevron(id, e)
                                                        }}>
                                                            <div className="card-header p-3" id={`heading${id}`}>
                                                                <h5 className="mb-0">
                                                                    <a href="#!" data-toggle="collapse" data-target={`#collapse${id}`} aria-expanded="false" aria-controls={`collapse${id}`} className="collapsed d-flex align-items-center justify-content-between w-100">
                                                                        <span>
                                                                            {
                                                                                activedChevron == id ?
                                                                                    <ChevronDown size={20} className="mr-1" /> : <ChevronRight size={20} className="mr-1" />
                                                                            }
                                                                            <span>{name}</span>
                                                                        </span>
                                                                        <GridVertical size={20} className="mr-1" />
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id={`collapse${id}`} className="card-body collapse" aria-labelledby={`heading${id}`} data-parent="#accordionItemUpdateTheme">
                                                                {_renderCategoryItemForm(id)}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                </Scrollbar>

                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <div className="col-lg-8 col-md-9 col-12 ">
                    <div className="row">
                        <div className="col">
                            <h3 className="text-primary text-center mb-3 ">Preview Tampilan Website Kustom</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mx-auto shadow bg-white preview_wrapper p-0" id="preview_iframe_wrapper" style={{ height: 'calc(720px * 0.6)', width: 'calc(1280px * 0.6)' }}>
                            <iframe key={changesKeyIframe} className="mx-auto" height="100%" width="100%" frameborder="0" src={`${APP_CONFIG.WEB_URL}product-special?debugMode=${params.product_uuid}`} id="preview_iframe" style={{ transform: 'scale(0.6)', transformOrigin: '0 0', width: '1280px', height: '720px' }}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomSpecialProductPage