import axios from "axios"
import { useState } from "react"
import APP_CONFIG from "../../utils/APP_CONFIG"

export default function VerificationPage() {

    const [productCode, setProductCode] = useState()

    const doVerif = () => {
        axios.get(APP_CONFIG.API_URL + 'products/product-code/' + productCode)
            .then((res) => {
                alert('Berhasil, Serial Number Valid')
            }).catch((err) => {
                alert('Gagal, Serial Number Tidak Valid')
            })
    }

    return (
        <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '100vh' }}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input type="text" placeholder="Kode SN" className="form-control" value={productCode} onChange={(event) => { setProductCode(event.target.value) }} />
                <button className="btn btn-success" style={{ marginTop: 10 }} onClick={() => {
                    doVerif()
                }}>Verifikasi</button>
            </div>
        </div>
    )
}