import { Outlet } from "react-router-dom";

export default function DashboardPage() {

    return (
        <div>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Dashboard</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">Dashboard</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3 className='mb-3'>Dashboard</h3>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <h1>Selamat Datang</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}