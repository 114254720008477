import React, { useEffect, useState } from "react";
import { QrCodeComponent } from "./QrCodeComponent";

export const ComponentToPrint = React.forwardRef((props, ref) => {

    const [labelQrs, setLabelQrs] = useState([])

    useEffect(() => {
        setLabelQrs(props.labels)
        if (props.labels.length > 0) {
            setTimeout(() => {
                props.printRef.current.handleClick();
            }, 500);
        }
    }, [props.labels])

    return (
        <div ref={ref} style={{ width: 175 }}>
            {
                labelQrs.map((labelQr) => {
                    return labelQr ?
                        <QrCodeComponent value={labelQr} /> : <></>
                })
            }
        </div>
    );
});