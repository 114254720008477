import logo from './../../assets/images/logo/logo.svg'
import logoSm from './../../assets/images/logo/logo-sm.svg'
import { Link, NavLink } from 'react-router-dom'
import { Home, PackageIcon, Gift, UserAccount, LogOut } from '@styled-icons/boxicons-solid'

export default function SidebarLayout() {
    return (
        <nav className="pc-sidebar ">
            <div className="navbar-wrapper">
                <div className="m-header">
                    <Link to={"/panel/dashboard"} className="b-brand">
                        <div className='d-flex align-items-center' style={{ gap: 7 }}>
                            <img src={require('./../../assets/images/logo/garuda-black.png')} style={{ width: 30, borderRadius: '100%' }} />
                            <h3 style={{ color: 'white', fontWeight: '600' }} className='mb-0'>Garuda Gold</h3>
                        </div>
                    </Link>
                </div>
                <div className="navbar-content">
                    <ul className="pc-navbar">
                        <li className="pc-item pc-caption">
                            <label>Menu</label>
                        </li>
                        <li className="pc-item">
                            <NavLink to={"/panel/dashboard"} className="pc-link ">
                                <span className="pc-micon">
                                    <Home color='#ffffff' />
                                </span>
                                <span className="pc-mtext font-weight-bold">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="pc-item">
                            <NavLink to={"/panel/common-product"} className="pc-link">
                                <span className="pc-micon">
                                    <PackageIcon />
                                </span>
                                <span className="pc-mtext font-weight-bold">Produk</span>
                            </NavLink>
                        </li>
                        <li className="pc-item">
                            <NavLink to={"/panel/special-product"} className="pc-link ">
                                <span className="pc-micon">
                                    <Gift />
                                </span>
                                <span className="pc-mtext font-weight-bold">Produk Spesial</span>
                            </NavLink>
                        </li>
                        <li className="pc-item">
                            <NavLink to={"/panel/reseller"} className="pc-link ">
                                <span className="pc-micon">
                                    <UserAccount />
                                </span>
                                <span className="pc-mtext font-weight-bold">Reseller</span>
                            </NavLink>
                        </li>
                        {/* <li className="pc-item">
                            <NavLink to={"/panel/profile"} className="pc-link ">
                                <span className="pc-micon">
                                    <User />
                                </span>
                                <span className="pc-mtext font-weight-bold">Profil</span>
                            </NavLink>
                        </li> */}
                        <li className="pc-item">
                            <NavLink to={"/login"} className="pc-link ">
                                <span className="pc-micon">
                                    <LogOut />
                                </span>
                                <span className="pc-mtext font-weight-bold">Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    )
}

const styles = {
    aHref: {
        backgroundColor: 'red'
    }
}