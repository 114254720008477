import { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import { Printer } from 'styled-icons/boxicons-solid';
import APP_CONFIG from '../../utils/APP_CONFIG';
import axios from 'axios';
import TokenUtil from '../../utils/TokenUtil';
import StringUtil from '../../utils/StringUtil';
import Barcode from 'react-barcode';
import { ComponentToPrint } from '../../components/ComponentToPrint';
import { useReactToPrint } from 'react-to-print';

export default function CommonProductHistoryDetailPage() {
    const [product, setProduct] = useState({})
    const { product_uuid, product_label_uuid } = useParams();
    const [labels, setLabels] = useState([])
    const [labelsRes, setLabelsRes] = useState([])
    const componentRef = useRef();

    useEffect(() => {
        loadProduct()
        loadProductLabelItems()
    }, [])

    const loadProduct = () => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `products/${product_uuid}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setProduct(res.data.data)
        })
    }

    const printBarcode = (label) => {
        setLabels([label])
        handlePrint()
    }

    const cb = () => {
        setLabels([])
    }

    const handlePrint = useReactToPrint({
        pageStyle: `@page { size: 3.5in 1in; margin: 0; }`,
        content: () => componentRef.current,
    });

    const loadProductLabelItems = () => {
        axios.get(APP_CONFIG.API_URL + `products/${product_label_uuid}/label-item-histories?p=1&s=50`, {
            headers: {
                Authorization: 'Bearer ' + TokenUtil.getApiToken()
            }
        }).then((res) => {
            setLabelsRes(res.data.data.label_item.data)
        })
    }

    return (
        <div>
            <div
                style={{ display: 'none' }}
            >
                {
                    labels.length > 0 ?
                        <ComponentToPrint ref={componentRef} labels={labels} handlePrint={handlePrint} cb={cb} /> : <></>
                }
            </div>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Produk</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/panel/common-product'}>List Produk</Link></li>
                                <li className="breadcrumb-item"><Link to={'/panel/common-product-history'}>Riwayat Generate Label</Link></li>
                                <li className="breadcrumb-item">Detail Generate Label 2023-06-15</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className='mt-3 mt-lg-5 mb-3 text-primary font-weight-bold'>Detail Generate Label 2023-06-15</h3>

            <div className="card">
                <div className="card-body">
                    <table className='table' style={{ tableLayout: 'auto' }}>
                        <thead>
                            <tr>
                                <td><b>Nama Produk</b></td>
                                <td><b>:</b></td>
                                <td>{product.product_name}</td>
                            </tr>
                            <tr>
                                <td><b>Kode Produk</b></td>
                                <td><b>:</b></td>
                                <td>{product.product_code}</td>
                            </tr>
                            <tr>
                                <td><b>Harga</b></td>
                                <td><b>:</b></td>
                                <td>Rp{StringUtil.formatRupiah(product.price)}</td>
                            </tr>
                            <tr>
                                <td><b>Gambar</b></td>
                                <td><b>:</b></td>
                                <td>
                                    <img src={product.photo} width={80} className='img-thumbnail' alt="" />
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <h3 className='mt-3 mt-lg-1 mb-3'>List Generate Label</h3>

            <div className="row">
                <div className="col">
                    <table className="table table-bordered ">
                        <thead className='thead-dark'>
                            <tr>
                                <th>#</th>
                                <th>SN</th>
                                <th>Barcode</th>
                                <th className='text-center'>Aksi</th>
                            </tr>
                        </thead>
                        <tbody className='bg-white'>
                            {
                                labelsRes.map((label, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{label.serial_number}</td>
                                            <td>
                                                {
                                                    <Barcode value={label.serial_number} />
                                                }
                                            </td>
                                            <td className='text-center'>
                                                <a onClick={() => {
                                                    printBarcode(label)
                                                }} target="_blank" className="btn btn-sm btn-primary"><Printer className="mr-1" /> Cetak</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}