import { Plus } from '@styled-icons/entypo'
import { Edit, Trash, Save, Barcode as BarcodeIcon } from '@styled-icons/boxicons-solid'
import { Button, Form, Modal } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import APP_CONFIG from '../../utils/APP_CONFIG';
import { ToastContainer, toast } from 'react-toastify';
import StringUtil from '../../utils/StringUtil';
import TokenUtil from '../../utils/TokenUtil';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from '../../components/ComponentToPrint';

export default function CommonProductPage() {

    /**
     * Useffect
     *
     */
    useEffect(() => {
        loadProducts()
    }, [])

    /**
     * Ref
     *
     */
    const componentRef = useRef();
    const printRef = useRef();

    /**
     * State
     *
     */
    const [products, setProducts] = useState([])
    const [addPhoto, setAddPhoto] = useState('');

    const [productGenerateLabel, setProductGenerateLabel] = useState({})
    const [labels, setLabels] = useState([])
    const [show, setShow] = useState(false);

    const [productEditObj, setProductEditObj] = useState({})
    const [productImageEditObj, setProductEditImageObj] = useState()

    const handleSaveCreateProduct = (e) => {
        e.preventDefault()
        const token = localStorage.getItem('api_token')
        const formData = new FormData(e.target);
        formData.append('status', 'common')

        axios.post(APP_CONFIG.API_URL + 'products', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer " + token
            },
        }).then((res) => {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Produk telah ditambahkan',
                icon: 'success',
            })

            setAddPhoto(null)
            setShow(false);
            loadProducts()
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const loadProducts = () => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `products?status=common`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setProducts(res.data.data.products.data)
        })
    }

    const handleSaveGenerateLabel = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);

        axios.post(APP_CONFIG.API_URL + `products/${productGenerateLabel.uuid}/generate-labels`, formData, {
            headers: {
                'Authorization': "Bearer " + TokenUtil.getApiToken()
            },
        }).then((res) => {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Generate Label berhasil dilakukan',
                icon: 'success',
            })

            setProductGenerateLabel({})
            setShow(false);
            setLabels(res.data.data)
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const handleUpdateProduct = (e) => {
        e.preventDefault()

        const token = localStorage.getItem('api_token')
        const formData = new FormData(e.target);
        formData.append('status', 'common')

        if (productImageEditObj) {
            formData.append('photo', productImageEditObj)
        }

        axios.post(APP_CONFIG.API_URL + `products/${productEditObj.uuid}/update`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer " + token
            },
        }).then((res) => {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Produk telah diupdate',
                icon: 'success',
            })

            setProductEditImageObj(null)
            setProductEditObj({})
            setShow(false);
            loadProducts()
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const handleDeleteProduct = (product) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data produk akan terhapus, secara permanen!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(APP_CONFIG.API_URL + `products/${product.uuid}`, {
                    headers: {
                        'Authorization': "Bearer " + TokenUtil.getApiToken()
                    },
                }).then((res) => {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: 'Produk telah dihapus',
                        icon: 'success',
                    })

                    loadProducts()
                }).catch((err) => {
                    if (err.response.status == 422 || err.response.status == 400) {
                        toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                            type: 'error',
                        });
                    }
                })
            }
        })
    }

    const _renderProducts = () => {
        return products.map((product, i) => {
            return (
                <tr>
                    <td>{i + 1}</td>
                    <td>
                        <img src={product.photo} width={80} className='img-thumbnail' alt="" />
                    </td>
                    <td>{product.product_name}</td>
                    <td>{product.product_code}</td>
                    <td>Rp{StringUtil.formatRupiah(product.price)}</td>
                    <td>{product.prefix}</td>
                    <td>{product.suffix}</td>
                    <td>
                        <button className='btn btn-sm btn-warning' onClick={() => {
                            setProductGenerateLabel(product)
                            setShow('generate-label')
                        }}><BarcodeIcon size={17} className='mr-1' />Generate Label</button>
                    </td>
                    <td>
                        <div className='d-flex justify-content-center align-items-center'>
                            <button className='btn btn-sm btn-primary' onClick={() => {
                                setProductEditObj(product)
                                setProductEditImageObj(null)
                                setShow('edit-product')
                            }}><Edit className='mr-1' />Edit</button>
                            <button className='btn btn-sm btn-danger mx-1' onClick={() => {
                                handleDeleteProduct(product)
                            }}><Trash className='mr-1' />Hapus</button>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div>
            <ReactToPrint
                trigger={() => <React.Fragment />}
                content={() => componentRef.current}
                ref={printRef}
                pageStyle={`@page {size: 1.24in 1.24in; margin: 1px; }`}
            />
            <ToastContainer />
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Produk</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">List Produk</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: 'none' }}>
                <ComponentToPrint ref={componentRef} labels={labels} printRef={printRef} />
            </div>
            <h3 className='mt-3 mt-lg-5 mb-3 text-primary font-weight-bold'>List Produk</h3>

            {/* Modal Create Product  */}
            <Modal show={show == 'create-product'} onHide={() => { setShow(false) }} size="lg">
                <Form onSubmit={handleSaveCreateProduct} encType='multipart/form-data'>
                    <Modal.Header>
                        <Modal.Title>Tambah Produk</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="product_name">
                            <Form.Label>Nama Produk</Form.Label>
                            <Form.Control type="text" placeholder="Nama Produk" name='product_name' />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="product_code">
                            <Form.Label>Kode Produk</Form.Label>
                            <Form.Control type="text" placeholder="Kode Produk" name='product_code' />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="price">
                            <Form.Label>Harga</Form.Label>
                            <Form.Control type="number" placeholder="Harga" name='price' />
                        </Form.Group>
                        <Form.Group controlId="image" className="mb-3">
                            <Form.Label>Gambar</Form.Label><br />
                            <img src={addPhoto ? URL.createObjectURL(addPhoto) : require('./../../assets/images/samples/noimage.png')} className='img-thumbnail mb-2' style={{ width: 100 }} alt="photo" />
                            <Form.Control type="file" accept='image/*' onChange={(event) => { setAddPhoto(event.target.files[0]) }} name='photo' />
                        </Form.Group>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="prefix">
                                    <Form.Label>Prefix</Form.Label>
                                    <Form.Control type="text" placeholder="Prefix" name='prefix' />
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="suffix">
                                    <Form.Label>Sufix</Form.Label>
                                    <Form.Control type="text" placeholder="Sufix" name='suffix' />
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShow(false) }}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            <Save /> Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* End of Modal Create Product */}

            {/* Modal Edit Product  */}
            <Modal show={show == 'edit-product'} onHide={() => { setShow(false) }} size="lg">
                <Form onSubmit={handleUpdateProduct} encType='multipart/form-data'>
                    <Modal.Header>
                        <Modal.Title>Edit Produk</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="product_name">
                            <Form.Label>Nama Produk</Form.Label>
                            <Form.Control type="text" placeholder="Nama Produk" value={productEditObj.product_name} name='product_name' onChange={(e) => {
                                setProductEditObj(() => {
                                    let obj = Object.assign({}, productEditObj)
                                    obj.product_name = e.target.value

                                    return obj
                                })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="product_code">
                            <Form.Label>Kode Produk</Form.Label>
                            <Form.Control type="text" placeholder="Kode Produk" value={productEditObj.product_code} name='product_code' onChange={(e) => {
                                setProductEditObj(() => {
                                    let obj = Object.assign({}, productEditObj)
                                    obj.product_code = e.target.value

                                    return obj
                                })
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="price">
                            <Form.Label>Harga</Form.Label>
                            <Form.Control type="number" placeholder="Harga" value={productEditObj.price} name='price' onChange={(e) => {
                                setProductEditObj(() => {
                                    let obj = Object.assign({}, productEditObj)
                                    obj.price = e.target.value

                                    return obj
                                })
                            }} />
                        </Form.Group>
                        <Form.Group controlId="image" className="mb-3">
                            <Form.Label>Gambar</Form.Label><br />
                            <img src={productImageEditObj ? URL.createObjectURL(productImageEditObj) : (productEditObj.photo ? productEditObj.photo : require('./../../assets/images/samples/noimage.png'))} className='img-thumbnail mb-2' style={{ width: 100 }} alt="photo" />
                            <Form.Control type="file" name='photo' onChange={(event) => {
                                setProductEditImageObj(event.target.files[0])
                            }} />
                        </Form.Group>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="prefix">
                                    <Form.Label>Prefix</Form.Label>
                                    <Form.Control type="text" placeholder="Prefix" name='prefix' value={productEditObj.prefix} onChange={(e) => {
                                        setProductEditObj(() => {
                                            let obj = Object.assign({}, productEditObj)
                                            obj.prefix = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="suffix">
                                    <Form.Label>Sufix</Form.Label>
                                    <Form.Control type="text" placeholder="Suffix" name='suffix' value={productEditObj.suffix} onChange={(e) => {
                                        setProductEditObj(() => {
                                            let obj = Object.assign({}, productEditObj)
                                            obj.suffix = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShow(false) }}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            <Save /> Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* End of Modal Edit Product */}

            {/* Modal Generate Label  */}
            <Modal show={show == 'generate-label'} onHide={() => { setShow(false) }} size="lg">
                <Form onSubmit={handleSaveGenerateLabel}>
                    <Modal.Header>
                        <Modal.Title>Generate Label</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='p-4 border'>
                            <table className='table' style={{ tableLayout: 'auto' }}>
                                <thead>
                                    <tr>
                                        <td><b>Nama Produk</b></td>
                                        <td><b>:</b></td>
                                        <td>{productGenerateLabel.product_name}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Kode Produk</b></td>
                                        <td><b>:</b></td>
                                        <td>{productGenerateLabel.product_code}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Harga</b></td>
                                        <td><b>:</b></td>
                                        <td>Rp{StringUtil.formatRupiah(productGenerateLabel.price)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Gambar</b></td>
                                        <td><b>:</b></td>
                                        <td>
                                            <img src={productGenerateLabel.photo ? productGenerateLabel.photo : require('./../../assets/images/samples/noimage.png')} width={80} className='img-thumbnail' alt="" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Prefix</b></td>
                                        <td><b>:</b></td>
                                        <td>{productGenerateLabel.prefix}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Suffix</b></td>
                                        <td><b>:</b></td>
                                        <td>{productGenerateLabel.suffix}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                        <Form.Group className="mb-3 mt-3" controlId="amount">
                            <Form.Label>Jumlah</Form.Label>
                            <Form.Control type="number" name='amount' placeholder="Jumlah" defaultValue={0} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            <BarcodeIcon /> Generate Label
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* End of Modal Generate Label */}

            <div className="row">
                <div className="col">
                    <button type='button' onClick={() => {
                        setAddPhoto(null)
                        setShow('create-product')
                    }} className="btn btn-success d-flex align-items-center justify-content-center" style={{ gap: '.3rem' }}><Plus /> Tambah Produk</button>

                    <div className="card mt-3">
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Gambar</th>
                                        <th>Nama Produk</th>
                                        <th>Kode Produk</th>
                                        <th>Harga</th>
                                        <th>Prefix</th>
                                        <th>Suffix</th>
                                        <th>Generator</th>
                                        <th className='text-center'>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_renderProducts()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
