import { Plus } from '@styled-icons/entypo'
import { Edit, Trash, Save } from '@styled-icons/boxicons-solid'
import { Button, Form, Modal } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import APP_CONFIG from '../../utils/APP_CONFIG';
import { ToastContainer, toast } from 'react-toastify';
import TokenUtil from '../../utils/TokenUtil';
import { useReactToPrint } from 'react-to-print';

export default function ResellerPage() {

    /**
     * Useffect
     *
     */
    useEffect(() => {
        loadResllers()
        reloadInitialLocation()
    }, [])

    /**
     * Ref
     *
     */
    const componentRef = useRef();

    /**
     * State
     *
     */
    const [resellers, setResellers] = useState([])
    const [addPhoto, setAddPhoto] = useState('');

    const [show, setShow] = useState(false);

    const [resellerEditObj, setResellerEditObj] = useState({})
    const [resellerImageEditObj, setResellerEditImageObj] = useState()

    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [villages, setVillages] = useState([])

    const reloadInitialLocation = () => {
        loadProvinces((cbProvinces) => {
            loadCities(cbProvinces[0].area_code, (cbCities) => {
                loadDistricts(cbCities[0].area_code, (districts) => {
                    loadVillages(districts[0].area_code)
                })
            })
        })
    }

    const handleSaveCreateReseller = (e) => {
        e.preventDefault()
        const token = localStorage.getItem('api_token')
        const formData = new FormData(e.target);
        formData.append('status', 'common')

        axios.post(APP_CONFIG.API_URL + 'resellers', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer " + token
            },
        }).then((res) => {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Reseller telah ditambahkan',
                icon: 'success',
            })

            setAddPhoto(null)
            setShow(false);
            loadResllers()
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const loadProvinces = (cb = null) => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `regions/get-region?area_type=p`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setProvinces(res.data.data)

            if (cb) {
                cb(res.data.data)
            }
        })
    }

    const loadCities = (parent_area_code, cb = null) => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `regions/get-region?area_type=c&parrent_area_code=${parent_area_code}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setCities(res.data.data)

            if (cb) {
                cb(res.data.data)
            }
        })
    }

    const loadDistricts = (parent_area_code, cb) => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `regions/get-region?area_type=d&parrent_area_code=${parent_area_code}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setDistricts(res.data.data)

            if (cb) {
                cb(res.data.data)
            }
        })
    }

    const loadVillages = (parent_area_code, cb) => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `regions/get-region?area_type=v&parrent_area_code=${parent_area_code}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setVillages(res.data.data)

            if (cb) {
                cb(res.data.data)
            }
        })
    }

    const loadResllers = () => {
        const token = localStorage.getItem('api_token')

        axios.get(APP_CONFIG.API_URL + `resellers`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setResellers(res.data.data.resellers)
        })
    }

    const handleUpdateReseller = (e) => {
        e.preventDefault()

        const token = localStorage.getItem('api_token')
        const formData = new FormData(e.target);
        formData.append('status', 'common')

        if (resellerImageEditObj) {
            formData.append('photo', resellerImageEditObj)
        }

        axios.post(APP_CONFIG.API_URL + `resellers/${resellerEditObj.uuid}/update`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer " + token
            },
        }).then((res) => {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Reseller telah diupdate',
                icon: 'success',
            })

            setResellerEditImageObj(null)
            setResellerEditObj({})
            setShow(false);
            loadResllers()
        }).catch((err) => {
            if (err.response.status == 422 || err.response.status == 400) {
                toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                    type: 'error',
                });
            }
        })
    }

    const handleDeleteReseller = (reseller) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data reseller akan terhapus, secara permanen!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(APP_CONFIG.API_URL + `resellers/${reseller.uuid}`, {
                    headers: {
                        'Authorization': "Bearer " + TokenUtil.getApiToken()
                    },
                }).then((res) => {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: 'Reseller telah dihapus',
                        icon: 'success',
                    })

                    loadResllers()
                }).catch((err) => {
                    if (err.response.status == 422 || err.response.status == 400) {
                        toast(`${err.response.data.msg} ${err.response.data.error ? ', ' + err.response.data.error : ''}`, {
                            type: 'error',
                        });
                    }
                })
            }
        })
    }

    const _renderResellers = () => {
        return resellers.map((reseller, i) => {
            return (
                <tr>
                    <td>{i + 1}</td>
                    <td>
                        <img src={reseller.photo} width={80} className='img-thumbnail' alt="" />
                    </td>
                    <td>{reseller.name}</td>
                    <td>{reseller.code}</td>
                    <td>{reseller.email}</td>
                    <td>{reseller.whatsapp_number}</td>
                    <td>
                        <div className='d-flex justify-content-center align-items-center'>
                            <button className='btn btn-sm btn-primary' onClick={() => {
                                setResellerEditObj(reseller)
                                loadCities(reseller.province_area_code, () => {
                                    loadDistricts(reseller.city_area_code, () => {
                                        loadVillages(reseller.district_area_code)
                                    })
                                })
                                setResellerEditImageObj(null)
                                setShow('edit-reseller')
                            }}><Edit className='mr-1' />Edit</button>
                            <button className='btn btn-sm btn-danger mx-1' onClick={() => {
                                handleDeleteReseller(reseller)
                            }}><Trash className='mr-1' />Hapus</button>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div>

            <ToastContainer />
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Reseller</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">List Reseller</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className='mt-3 mt-lg-5 mb-3 text-primary font-weight-bold'>List Reseller</h3>

            {/* Modal Create Reseller  */}
            <Modal show={show == 'create-reseller'} onHide={() => { setShow(false) }} size="xl">
                <Form onSubmit={handleSaveCreateReseller} encType='multipart/form-data'>
                    <Modal.Header>
                        <Modal.Title>Tambah Reseller</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nama</Form.Label>
                                    <Form.Control type="text" placeholder="Nama" name='name' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="code">
                                    <Form.Label>Kode</Form.Label>
                                    <Form.Control type="text" placeholder="Kode" name='code' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" name='email' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="whatsapp_number">
                                    <Form.Label>Nomor Whatsapp</Form.Label>
                                    <Form.Control type="text" placeholder="Nomor Whatsapp" name='whatsapp_number' />
                                </Form.Group>
                                <Form.Group controlId="image" className="mb-3">
                                    <Form.Label>Foto</Form.Label><br />
                                    <img src={addPhoto ? URL.createObjectURL(addPhoto) : require('./../../assets/images/samples/noimage.png')} className='img-thumbnail mb-2' style={{ width: 100 }} alt="photo" />
                                    <Form.Control type="file" accept='image/*' onChange={(event) => { setAddPhoto(event.target.files[0]) }} name='photo' />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group controlId='province_area_code' className='mb-3'>
                                    <Form.Label>Provinsi</Form.Label>
                                    <Form.Select name='province_area_code' onChange={(e) => {
                                        loadCities(e.target.value, (cities) => {
                                            loadDistricts(cities[0].area_code, (districts) => {
                                                loadVillages(districts[0].area_code)
                                            })
                                        })
                                    }}>
                                        {
                                            provinces.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId='city_area_code' className='mb-3'>
                                    <Form.Label>Kabupaten/Kota</Form.Label>
                                    <Form.Select name='city_area_code' onChange={(e) => {
                                        loadDistricts(e.target.value, (districts) => {
                                            loadVillages(districts[0].area_code)
                                        })
                                    }}>
                                        {
                                            cities.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId='district_area_code' className='mb-3'>
                                    <Form.Label>Kecamatan</Form.Label>
                                    <Form.Select name='district_area_code' onChange={(e) => {
                                        loadVillages(e.target.value)
                                    }}>
                                        {
                                            districts.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId='village_area_code' className='mb-3'>
                                    <Form.Label>Desa</Form.Label>
                                    <Form.Select name='village_area_code'>
                                        {
                                            villages.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Alamat</Form.Label>
                                    <Form.Control as={'textarea'} placeholder="Alamat" name='address' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Kodepos</Form.Label>
                                    <Form.Control type='number' maxLength={5} placeholder="Kodepos" name='postal_code' />
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShow(false) }}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            <Save /> Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* End of Modal Create Reseller */}

            {/* Modal Edit Reseller  */}
            <Modal show={show == 'edit-reseller'} onHide={() => { setShow(false) }} size="xl">
                <Form onSubmit={handleUpdateReseller} encType='multipart/form-data'>
                    <Modal.Header>
                        <Modal.Title>Edit Reseller</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nama</Form.Label>
                                    <Form.Control type="text" placeholder="Nama" name='name' value={resellerEditObj.name} onChange={(e) => {
                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.name = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="code">
                                    <Form.Label>Kode</Form.Label>
                                    <Form.Control type="text" placeholder="Kode" name='code' value={resellerEditObj.code} onChange={(e) => {
                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.code = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" name='email' value={resellerEditObj.email} onChange={(e) => {
                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.email = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="whatsapp_number">
                                    <Form.Label>Nomor Whatsapp</Form.Label>
                                    <Form.Control type="text" placeholder="Nomor Whatsapp" name='whatsapp_number' value={resellerEditObj.whatsapp_number} onChange={(e) => {
                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.whatsapp_number = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                                <Form.Group controlId="photo" className="mb-3">
                                    <Form.Label>Foto</Form.Label><br />
                                    <img src={resellerImageEditObj ? URL.createObjectURL(resellerImageEditObj) : (resellerEditObj.photo ? resellerEditObj.photo : require('./../../assets/images/samples/noimage.png'))} className='img-thumbnail mb-2' style={{ width: 100 }} alt="photo" />
                                    <Form.Control type="file" name='photo' onChange={(event) => {
                                        setResellerEditImageObj(event.target.files[0])
                                    }} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group controlId='province_area_code' className='mb-3'>
                                    <Form.Label>Provinsi</Form.Label>
                                    <Form.Select name='province_area_code' value={resellerEditObj.province_area_code} onChange={(e) => {
                                        loadCities(e.target.value, (cities) => {
                                            loadDistricts(cities[0].area_code, (districts) => {
                                                loadVillages(districts[0].area_code)
                                            })
                                        })

                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.province_area_code = e.target.value

                                            return obj
                                        })
                                    }}>
                                        {
                                            provinces.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId='city_area_code' className='mb-3'>
                                    <Form.Label>Kabupaten/Kota</Form.Label>
                                    <Form.Select name='city_area_code' value={resellerEditObj.city_area_code} onChange={(e) => {
                                        loadDistricts(e.target.value, (districts) => {
                                            loadVillages(districts[0].area_code)
                                        })

                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.city_area_code = e.target.value

                                            return obj
                                        })
                                    }}>
                                        {
                                            cities.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId='district_area_code' className='mb-3'>
                                    <Form.Label>Kecamatan</Form.Label>
                                    <Form.Select name='district_area_code' value={resellerEditObj.district_area_code} onChange={(e) => {
                                        loadVillages(e.target.value)

                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.district_area_code = e.target.value

                                            return obj
                                        })
                                    }}>
                                        {
                                            districts.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId='village_area_code' className='mb-3'>
                                    <Form.Label>Desa</Form.Label>
                                    <Form.Select name='village_area_code' value={resellerEditObj.village_area_code} onChange={(e) => {
                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.village_area_code = e.target.value

                                            return obj
                                        })
                                    }}>
                                        {
                                            villages.map((e) => {
                                                return (<option value={e.area_code}>{e.area_name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Alamat</Form.Label>
                                    <Form.Control as={'textarea'} placeholder="Alamat" name='address' value={resellerEditObj.address} onChange={(e) => {
                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.address = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="postal_code">
                                    <Form.Label>Kodepos</Form.Label>
                                    <Form.Control type='number' maxLength={5} placeholder="Kodepos" name='postal_code' value={resellerEditObj.postal_code} onChange={(e) => {
                                        setResellerEditObj(() => {
                                            let obj = Object.assign({}, resellerEditObj)
                                            obj.postal_code = e.target.value

                                            return obj
                                        })
                                    }} />
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShow(false) }}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            <Save /> Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* End of Modal Edit Reseller */}

            <div className="row">
                <div className="col">
                    <button type='button' onClick={() => {
                        setAddPhoto(null)
                        reloadInitialLocation()
                        setShow('create-reseller')
                    }} className="btn btn-success d-flex align-items-center justify-content-center" style={{ gap: '.3rem' }}><Plus /> Tambah Reseller</button>

                    <div className="card mt-3">
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Foto</th>
                                        <th>Nama</th>
                                        <th>Kode</th>
                                        <th>Email</th>
                                        <th>Nomor Whatsapp</th>
                                        <th className='text-center'>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_renderResellers()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
