import { useNavigate } from 'react-router-dom'
import avatar from './../../assets/images/samples/avatar.webp'
import { Power } from 'styled-icons/feather'

export default function NavLayout() {
    const navigate = useNavigate()

    return (
        <header className="pc-header ">
            <div className="header-wrapper">
                <div className="ml-auto">
                    <ul className="list-unstyled">
                        <li className="dropdown pc-h-item">
                            <a className="pc-head-link dropdown-toggle arrow-none mr-0" data-toggle="dropdown" href="#"
                                role="button" aria-haspopup="false" aria-expanded="false">
                                <img src={require('./../../assets/images/logo/logo.jpg')} alt="user-image" className="user-avtar" />
                                <span>
                                    <span className="user-name" style={{ color: '#dca524' }}>Admin</span>
                                    <span className="user-desc">Administrator</span>
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right pc-h-dropdown">
                                <a href="#!" className="dropdown-item" onClick={() => {
                                    localStorage.removeItem('api_token')
                                    navigate('/login')
                                }}>
                                    <Power />
                                    <span>Logout</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </header >
    )

}