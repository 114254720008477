import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import LoginPage from './pages/auth/LoginPage';
import DashboardPage from './pages/panel/DashboardPage';
import AppLayout from './layouts/AppLayout';
import CommonProductPage from './pages/panel/CommonProductPage';
import CommonProductHistoryPage from './pages/panel/CommonProductHistoryPage';
import CommonProductHistoryDetailPage from './pages/panel/CommonProductHistoryDetailPage';
import SpecialProductPage from './pages/panel/SpecialProductPage';
import SpecialProductHistoryPage from './pages/panel/SpecialProductHistoryPage';
import SpecialProductHistoryDetailPage from './pages/panel/SpecialProductHistoryDetailPage';
import ProfilePage from './pages/panel/ProfilePage';
import VerificationPage from './pages/panel/VerificationPage';
import CustomTemaPage from './pages/panel/CustomTemaPage';
import ResellerPage from './pages/panel/ResellerPage';
import CustomSpecialProductPage from './pages/panel/CustomSpecialProductPage';

const router = createBrowserRouter([
    {
        "path": "",
        children: [
            {
                path: '',
                element: <Navigate to={'/login'} replace />
            }
        ]
    },
    {
        path: "login",
        element: <LoginPage />,
    },
    {
        path: "panel",
        element: <AppLayout />,
        children: [
            {
                path: "dashboard",
                element: <DashboardPage />,
            },
            {
                path: "common-product",
                children: [
                    {
                        path: '',
                        element: <CommonProductPage />
                    },
                    {
                        path: ':product_uuid/history',
                        element: <CommonProductHistoryPage />
                    },
                    {
                        path: ':product_uuid/history/:product_label_uuid',
                        element: <CommonProductHistoryDetailPage />
                    }
                ]
            },
            {
                path: "special-product",
                children: [
                    {
                        path: '',
                        element: <SpecialProductPage />
                    },
                    {
                        path: ':product_uuid/customize-theme',
                        element: <CustomSpecialProductPage />
                    }
                ]
            },
            {
                path: "reseller",
                children: [
                    {
                        path: '',
                        element: <ResellerPage />
                    }
                ]
            }
        ],
    }, {
        path: "verification",
        children: [
            {
                path: '',
                element: <VerificationPage />
            },
        ]
    }, {
        path: "custom-tema",
        children: [
            {
                path: '',
                element: <CustomTemaPage />
            },
        ]
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
